@import './assets/styles/theme';
@import '~@mdi/font/scss/materialdesignicons';

html {
  -webkit-font-smoothing: antialiased;

  &.cdk-global-scrollblock {
    overflow: hidden;
  }

  *:focus {
    outline: unset;
  }
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: mat-font-family($typography);
}

