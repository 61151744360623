@import 'fonts/typography';

h1,
h2,
h3,
h4,
h5{
    text-transform: uppercase;
    text-decoration: none;
    margin: 0;
}

a {
    cursor: pointer;
    text-decoration: none;
}

.no-data {
  box-shadow: 0px 2px 6px rgba(0,0,0,.04);
  background-color: white;
  width: 100%;
  height: auto;
  text-align: center;
  display: block;
  padding: 30px 0px;

  .bg {
    background-color: var(--background) !important;
  }

  a {
    text-decoration: underline;
    color: var(--foreground-base);

    &:hover {
      text-decoration: none;
      color: #f58026 !important;
    }

    &:visited {
      color: inherit;
    }
  }
}
