.range {
  .mat-form-field-suffix {
    background-color: var(--accent-lighter-contrast);
    border-radius: 4px;
    padding: 2px 13px;
    cursor: pointer;
    position: relative;
    top: 7px;

    &:focus {
      outline: unset;
    }

    button {
      color: white !important;
      display: contents;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px !important;
  }

  .mat-date-range-input {
    background-color: white;
    padding: 5px 10px;
    border-radius: 4px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 1.7em !important;
    left: .5em;
  }
}
