#menu-wrapper{
  min-width: 200px;

  .menu-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--background-disabled-list-option);

    .menu-header-title {
      font-size: 16px;
      text-transform: uppercase;
      padding: 22px 15px;
    }
  }

  #menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width:100%;

    li {
      cursor: pointer;

      &.menu-reset {
        height: 200px;
        padding: 0;
        margin: 0;
        cursor: default;
      }

      &:hover:not(.menu-reset) {
        background-color: #F7F7F7;

        .mat-icon {
          color: #508ab9;
        }
      }

      &.active {
        background-color: #F7F7F7;

        .mat-icon {
          color: #508ab9;
        }
      }
    }

    & > li {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;

      padding: 10px 15px;
      background-color: white;
      position: relative;

      span {
        margin: 0px 0px 0 10px;
      }

      &:focus {
        outline: 0;
      }
    }

    .sub-menu {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
      width: max-content;

      .sub-menu-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        width: 100%;
      }

      .sub-menu-content {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        align-items: flex-start;
        margin-top: 18px;
        width: 100%;

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            margin: 0;
            padding: 10px 0 0 16px;
            border-bottom: 4px solid transparent;
            transition: width 0.3s ease;

            span {
              margin: 0;
              padding: 0;
            }

            &:after {
              content: '';
              position: relative;
              display: block;
              margin: 0 auto -1px auto;
              border-bottom: 1px solid var(--primary-500);
              width: 0px;
              transition: width 0.3s ease;
            }

            &:hover:after {
              width: 100%;
            }

            &:focus {
              outline: 0;
            }
          }
        }

        .sub-menu-line {
            height: 100%;
            padding-left: 12px;
            padding-right: 16px;

            .line {
              width: 2px;
              height: 100%;
              background-color: var(--primary-500);
            }
        }
      }
    }
  }
}
