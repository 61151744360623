@import "../../../layout_base";
@import "../../../layout";

.mat-tooltip {
    white-space: pre-line;
    background: var(--foreground-base) !important;
}

.alert-tooltip {
  white-space: pre-line;
  background: var(--warn-500) !important;
}
