mat-form-field {
  width: 100%;
  display: block !important;

  .mat-form-field-infix {
    width: 100px;

    .grp-readonly-textarea,
    .grp-readonly {
      display: flex;

      input[readonly]:not([readonly='false']) {
        color: var(--foreground-disabled);
      }

      .mdi-lock {
        color: var(--foreground-disabled-button);
      }
    }

    .grp-readonly {
      align-items: center;
    }
  }

  label {
    letter-spacing: 0.8px;
  }
}

[formGroupName="formula"] {
  .mat-form-field-infix {
    background-color: var(--black);
    padding: 10px !important;
  }
}

.filter .mat-form-field-label {
  text-transform: uppercase;
}

/* INPUT - FILE (HEADER CONFIG)*/
.header-input-file {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-content: center;

  .file-receipt {
    padding: 0px 16px 0 13px !important;
    box-shadow: unset !important;
    color: white !important;
    margin-right: 15px !important;
    background-color: var(--background-disabled-button) !important;

    .preview-image {
      width: 64px !important;
      height: 64px !important;
      border-radius: 50% !important;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
    }

    .file-infos {
      p{
        &:first-of-type {
          margin:4px 0 0 0 !important;
          padding: 0 !important;
        }

        &:last-of-type {
          margin:0 !important;
          padding: 0 !important;
        }
      }
    }
  }
}

.mat-form-field-prefix, .mat-form-field-suffix {
  align-self: self-end;

  .mat-datepicker-toggle button .mat-button-wrapper svg {
    width: 1.3em !important;
  }
}

