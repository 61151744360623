@import "themes/iprospect/variables";
@import "layout_base";

@function line-height($font-size) {
  @return (($font-size + ($font-size / 3.14159)) / $font-size);
}
/*
@function typography-level(
  $font-size,
  $line-height: line-height($font-size),
  $font-weight: 400,
  $font-family: null) {

  @return (
          font-size: $font-size,
          line-height: $line-height,
          font-weight: $font-weight,
          font-family: $font-family
  );
}
*/

/*
  Layout by AngularJS
 */

@mixin rtl-prop($ltr-prop, $rtl-prop, $value, $reset-value) {
  #{$ltr-prop}: $value;
  [dir=rtl] & {
    #{$ltr-prop}: $reset-value;
    #{$rtl-prop}: $value;
  }
}

@mixin flex-order-for-name($sizes:null) {
  @if $sizes == null {
    $sizes: '';

    .flex-order {
      order: 0;
    }
  }

  @for $i from -20 through 20 {
    $order: '';
    $suffix: '';

    @each $s in $sizes {
      @if $s != '' {
        $suffix: '#{$s}-#{$i}';
      } @else {
        $suffix: '#{$i}';
      }

      $order: '.flex-order-#{$suffix}';
    }

    #{$order} {
      order: #{$i};
    }
  }
}

@mixin offset-for-name($sizes:null) {
  @if $sizes == null {
    $sizes: '';
  }

  @for $i from 0 through 19 {
    $offsets: '';
    $suffix: '';

    @each $s in $sizes {
      @if $s != '' {
        $suffix: '#{$s}-#{$i * 5}';
      } @else {
        $suffix: '#{$i * 5}';
      }

      $offsets: '.offset-#{$suffix}, .flex-offset-#{$suffix}';
    }

    #{$offsets} {
      @if $i != 0 {
        @include rtl-prop(margin-left, margin-right, #{$i * 5 + '%'}, auto);
      } @else {
        @include rtl-prop(margin-left, margin-right, 0, auto);
      }
    }
  }

  @each $i in 33 {
    $offsets: '';
    $suffix: '';

    @each $s in $sizes {
      @if $s != '' {
        $suffix: '#{$s}-#{$i}';
      } @else {
        $suffix: '#{$i}';
      }

      $offsets: '.offset-#{$suffix}, .flex-offset-#{$suffix} ';
    }

    #{$offsets} {
      margin-left: calc(100% / 3);
    }
  }

  @each $i in 66 {
    $offsets: '';
    $suffix: '';

    @each $s in $sizes {
      @if $s != '' {
        $suffix: '#{$s}-#{$i}';
      } @else {
        $suffix: '#{$i}';
      }

      $offsets: '.offset-#{$suffix}, .flex-offset-#{$suffix} ';
    }

    #{$offsets} {
      @include rtl-prop(margin-left, margin-right, calc(200% / 3), auto);
    }
  }
}

@mixin layout-align-for-name($suffix: null) {

  // Alignment attributes for layout containers' children
  // Arrange on the Main Axis
  // center, start, end, space-between, space-around
  // flex-start is the default for justify-content
  // ------------------------------

  $name: 'layout-align';
  @if $suffix != null {
    $name: 'layout-align-#{$suffix}';
  }

  .#{$name},
  .#{$name}-start-stretch // defaults
  {
    @include layout-align;
  }

  // Main Axis Center
  .#{$name}-start,
  .#{$name}-start-start,
  .#{$name}-start-center,
  .#{$name}-start-end,
  .#{$name}-start-stretch {
    @include layout-align-horizontal-start;
  }

  // Main Axis Center
  .#{$name}-center, //stretch
  .#{$name}-center-start,
  .#{$name}-center-center,
  .#{$name}-center-end,
  .#{$name}-center-stretch {
    @include layout-align-horizontal-center;
  }

  // Main Axis End
  .#{$name}-end, //stretch
  .#{$name}-end-start,
  .#{$name}-end-center,
  .#{$name}-end-end,
  .#{$name}-end-stretch {
    @include layout-align-horizontal-end;

  }

  // Main Axis Space Around
  .#{$name}-space-around, //stretch
  .#{$name}-space-around-center,
  .#{$name}-space-around-start,
  .#{$name}-space-around-end,
  .#{$name}-space-around-stretch {
    @include layout-align-horizontal-space-around;

  }

  // Main Axis Space Between
  .#{$name}-space-between, //stretch
  .#{$name}-space-between-center,
  .#{$name}-space-between-start,
  .#{$name}-space-between-end,
  .#{$name}-space-between-stretch {
    @include layout-align-horizontal-space-between;

  }

  // Arrange on the Cross Axis
  // center, start, end
  // stretch is the default for align-items
  // ------------------------------

  // Cross Axis Start
  .#{$name}-start-start,
  .#{$name}-center-start,
  .#{$name}-end-start,
  .#{$name}-space-between-start,
  .#{$name}-space-around-start {
    @include layout-align-vertical-start;
  }

  // Cross Axis Center
  .#{$name}-start-center,
  .#{$name}-center-center,
  .#{$name}-end-center,
  .#{$name}-space-between-center,
  .#{$name}-space-around-center {
    @include layout-align-vertical-center;
  }

  // Cross Axis End
  .#{$name}-start-end,
  .#{$name}-center-end,
  .#{$name}-end-end,
  .#{$name}-space-between-end,
  .#{$name}-space-around-end {
    @include layout-align-vertical-end;
  }

  // Cross Axis Start
  .#{$name}-start-stretch,
  .#{$name}-center-stretch,
  .#{$name}-end-stretch,
  .#{$name}-space-between-stretch,
  .#{$name}-space-around-stretch {
    @include layout-align-vertical-stretch;
  }
}

@mixin flex-properties-for-name($name: null) {
  $flexName: 'layout-flex';
  @if $name != null {
    $flexName: 'layout-flex-#{$name}';
    $name: '-#{$name}';
  } @else {
    $name: '';
  }

  .#{$flexName} {
    @include layout-flex;
  }
  // === flex: 1 1 0%;

  .#{$flexName}-grow {
    @include layout-flex-grow;

  }
  .#{$flexName}-initial {
    @include layout-flex-initial;
  }
  .#{$flexName}-auto {
    @include layout-flex-auto;
  }
  .#{$flexName}-none {
    @include layout-flex-none;
  }
  .#{$flexName}-noshrink {
    @include layout-flex-noshrink;
  }
  .#{$flexName}-nogrow {
    @include layout-flex-nogrow;
  }

  // (1-20) * 5 = 0-100%
  @for $i from 0 through 20 {
    $value: #{$i * 5 + '%'};

    .#{$flexName}-#{$i * 5} {
      @include layout-flex-auto;
      max-width: #{$value};
      max-height: 100%;
    }

    .layout-row > .#{$flexName}-#{$i * 5} {
      @include layout-flex-auto;
      max-width: #{$value};
      max-height: 100%;

      // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
      @if $i == 0 {
        min-width: 0;
      }
    }

    .layout-column > .#{$flexName}-#{$i * 5} {
      @include layout-flex-auto;
      max-width: 100%;
      max-height: #{$value};
    }

    .layout#{$name}-row > .#{$flexName}-#{$i * 5} {
      @include layout-flex-auto;
      max-width: #{$value};
      max-height: 100%;

      // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
      @if $i == 0 {
        min-width: 0;
      }
    }

    .layout#{$name}-column > .#{$flexName}-#{$i * 5} {
      @include layout-flex-auto;
      max-width: 100%;
      max-height: #{$value};

      // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
      @if $i == 0 {
        min-height: 0;
      }
    }
  }

  .layout-row {

    > .#{$flexName}-33 {
      @include layout-flex-33(row);
    }
    > .#{$flexName}-66 {
      @include layout-flex-66(row);
    }
  }

  .layout-column {
    > .#{$flexName}-33 {
      @include layout-flex-33(column);
    }
    > .#{$flexName}-66 {
      @include layout-flex-66(column);
    }
  }
  //
  //.layout#{$name}-row {
  //  > .#{$flexName}-33 {
  //    flex: 1 1 100%;
  //    max-width: 33.33%;
  //    max-height: 100%;
  //    box-sizing: border-box;
  //  }
  //  > .#{$flexName}-66 {
  //    flex: 1 1 100%;
  //    max-width: 66.66%;
  //    max-height: 100%;
  //    box-sizing: border-box;
  //  }
  //
  //  // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
  //  > .flex {
  //    min-width: 0;
  //  }
  //
  //}
  //
  //.layout#{$name}-column {
  //  > .#{$flexName}-33 {
  //    flex: 1 1 100%;
  //    max-width: 100%;
  //    max-height: 33.33%;
  //    box-sizing: border-box;
  //  }
  //  > .#{$flexName}-66 {
  //    flex: 1 1 100%;
  //    max-width: 100%;
  //    max-height: 66.66%;
  //    box-sizing: border-box;
  //  }
  //
  //  // Bug workaround for http://crbug.com/546034 - flex issues on Chrome 48
  //  > .flex {
  //    min-height: 0;
  //  }
  //}
}

@mixin layout-for-name($name: null) {
  @if $name == null {
    $name: '';
  }
  @if $name != '' {
    $name: '-#{$name}';
  }

  .layout#{$name}, .layout#{$name}-column, .layout#{$name}-row {
    @include layout();
  }
  .layout#{$name}-column {
    @include layout-column();
  }
  .layout#{$name}-row {
    @include layout-row();
  }
}

@mixin layout-padding-margin() {

  // NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
  .layout-padding-sm > *,
  .layout-padding > .flex-sm {
    padding: $layout-gutter-width / 4;
  }

  .layout-padding,
  .layout-padding-gt-sm,
  .layout-padding-md,

    // NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
  .layout-padding > *,
  .layout-padding-gt-sm > *,
  .layout-padding-md > *,
  .layout-padding > .flex,
  .layout-padding > .flex-gt-sm,
  .layout-padding > .flex-md {
    padding: $layout-gutter-width / 2;
  }

  // NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
  .layout-padding-gt-md > *,
  .layout-padding-lg > *,
  .layout-padding-gt-lg > *,
  .layout-padding > .flex-gt-md,
  .layout-padding > .flex-lg,
  .layout-padding > .flex-lg,
  .layout-padding > .flex-gt-lg {
    padding: $layout-gutter-width / 1;
  }

  // Margin enhancements

  .layout-margin-sm > *,
  .layout-margin > .flex-sm {
    margin: $layout-gutter-width / 4;
  }

  .layout-margin,
  .layout-margin-gt-sm,
  .layout-margin-md,

    // NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
  .layout-margin > *,
  .layout-margin-gt-sm > *,
  .layout-margin-md > *,
  .layout-margin > .flex,
  .layout-margin > .flex-gt-sm,
  .layout-margin > .flex-md {
    margin: $layout-gutter-width / 2;
  }

  // NOTE: these`> *` selectors should only be applied for layout="row" or layout="column" children !!
  .layout-margin-gt-md > *,
  .layout-margin-lg > *,
  .layout-margin-gt-lg > *,
  .layout-margin > .flex-gt-md,
  .layout-margin > .flex-lg,
  .layout-margin > .flex-gt-lg {
    margin: $layout-gutter-width / 1;
  }

  .layout-wrap {
    flex-wrap: wrap;
  }

  .layout-nowrap {
    flex-wrap: nowrap;
  }

  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}

@mixin layouts_for_breakpoint($name:null) {
  //@include flex-order-for-name($name);
  //@include offset-for-name($name);
  //
  @include flex-properties-for-name($name);

  @include layout-align-for-name($name);
  @include layout-for-name($name);
}

////
@include layout-padding-margin();
////
@include media-content-xs {
  //@media (max-width: $layout-breakpoint-xs - 1) {
  // Xtra-SMALL  SCREEN
  .hide-xs, .hide {
    &:not(.show-xs):not(.show) {
      display: none;
    }
  }
  @include layouts_for_breakpoint(xs);
}

@include media-content-gt_xs {
  // BIGGER THAN Xtra-SMALL SCREEN
  @include layouts_for_breakpoint(gt-xs);
}

@include media-content-sm {
  .hide, .hide-gt-xs {
    &:not(.show-gt-xs):not(.show-sm):not(.show) {
      display: none;
    }
  }
  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none;
  }

  @include layouts_for_breakpoint(sm);
}

@include media-content-gt_sm {
  // BIGGER THAN SMALL SCREEN
  @include layouts_for_breakpoint(gt-sm);
}

@include media-content-md {
  // MEDIUM SCREEN
  .hide, .hide-gt-xs, .hide-gt-sm {
    &:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
      display: none;
    }
  }
  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }
  @include layouts_for_breakpoint(md);
}

@include media-content-gt_md {
  // BIGGER THAN MEDIUM SCREEN
  @include layouts_for_breakpoint(gt-md);
}

@include media-content-lg {
  // LARGE SCREEN
  .hide, .hide-gt-xs, .hide-gt-sm, .hide-gt-md {
    &:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
      display: none;
    }
  }
  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }

  @include layouts_for_breakpoint(lg);
}

@include media-content-gt_lg {
  @include layouts_for_breakpoint(gt-lg);
  @include layouts_for_breakpoint(xl);

  // BIGGER THAN LARGE SCREEN
  .hide, .hide-gt-xs, .hide-gt-sm, .hide-gt-md, .hide-gt-lg {
    &:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
      display: none;
    }
  }
  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none;
  }
}

@include layouts_for_breakpoint();

// General printing Rules
@media print {

  .hide-print:not(.show-print):not(.show) {
    display: none !important;
  }
}
