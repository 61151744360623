.empty-dialog {
  mat-dialog-container {
    transform: none;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    min-width: auto;

    .popin-wrapper {
      overflow: hidden;

      .mat-dialog-content {
        padding-top: 0 !important;
      }
    }
  }
}

mat-dialog-container {
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;

  background: url('/assets/images/popin.png') center center no-repeat !important;
  background-size: cover !important;
  //min-width: 400px;
  border-radius: 15px !important;
  padding: 15px 35px 15px 35px !important;

  .mat-dialog-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin: 0 0 15px !important;

    div:first-child {
      color: white;
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 55px;
      text-transform: uppercase;

      & > span {
        font-family: "Gotham-Black", sans-serif;
        font-weight: 1000;
        font-size: 25px;

        &:last-of-type {
          line-height: 15px;
          color: var(--black);
        }
      }
    }

    mat-icon {
      color: white;
      cursor: pointer;
    }
  }

  .mat-dialog-content {
    background-color: white;
    margin: 0 -35px !important;
    padding: 20px 35px !important;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04) !important;


    & > div {
      display: flex;
      flex-direction: column;

      span:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }

  .mat-dialog-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    background-color: white;
    padding: 13px 35px 13px 35px;
    margin: 0 -35px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04) !important;
  }
}
