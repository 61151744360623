$baseline-grid: 4px !default;
$layout-gutter-width: ($baseline-grid * 2) !default;
$font-size: 13px;
$line-height: line-height($font-size);

$layout-breakpoint-xs: 600px !default;
$layout-breakpoint-sm: 960px !default;
$layout-breakpoint-md: 1280px !default;
$layout-breakpoint-lg: 1920px !default;

$mdi-font-path: '/assets/fonts/mdi/';

$text-color : rgb(22,36,49);
$global-color : rgb(199,187,19);
$global-color-light:   rgb(250,233,137);
$text-success:rgb(122,141,98);
$text-error:   rgb(153,51,0);
