.loader-content {
  height:5vh;
  overflow-x:hidden;
  padding-top:7vh;
  position:absolute;
  transform:translate(-50%,-50%);
  width:15vw;
  &:before {
    animation:left-to-right 1s alternate infinite ease-in-out;
    background-image:linear-gradient(to right, transparent, var(--background), transparent);
    content:"";
    height:5vh;
    left:0;
    overflow:hidden;
    position:absolute;
    right:0;
    top:0;
  }
  /*&:after {
    background-image:linear-gradient(
        90deg,
        white,
        white 1vw,
        transparent 1vw,
        transparent 10vw
    );
    content:"";
    height:5vh;
    left:0;
    overflow:hidden;
    position:absolute;
    right:0;
    top:0;
  }*/
}

@keyframes left-to-right {
  0% {
    transform:translateX(-100%);
  }
  100% {
    transform:translateX(100%);
  }
}
