mat-chip {
  display: flex;
  align-items: center;
  align-content: center;

  margin-top: 10px;
  //width: 100%;

  span.label {
    flex: 1;
  }
}
