.custom-progress-spinner {
  position: relative;
  margin-right: 10px;

  .mat-progress-spinner,
  .mat-spinner {

    &:first-of-type {
      circle {
        stroke-opacity: 0.3 !important;
      }
    }

    &:last-of-type {
      position: absolute;
      top: 0;
      bottom: 0;
    }

    circle {
      stroke: rgba(26, 33, 39, 0.26) !important;
    }
  }
}


