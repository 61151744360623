@import 'material';
@import 'colors';
@import 'texts';
@import 'loaders/loaders';
@import 'src/assets/styles/themes/functions';
@import 'src/assets/styles/themes/mixins';

$font-size: 13px;
$typography: mat-typography-config(
    $font-family: '"Gotham-Book", sans-serif',
    $display-1: mat-typography-level($font-size * 2, line-height($font-size * 2), normal),
    $display-2: mat-typography-level($font-size * 3, line-height($font-size * 3), normal),
    $display-3: mat-typography-level($font-size * 4, line-height($font-size * 4), normal),
    $display-4: mat-typography-level($font-size * 8, line-height($font-size * 8), normal),
    $title: mat-typography-level($font-size + 10, line-height($font-size + 10), bold, '"Gotham-Bold", sans-serif'),
    $body-1: mat-typography-level($font-size, line-height($font-size), normal),
    $body-2: mat-typography-level($font-size, line-height($font-size), 500, '"Gotham-Medium", sans-serif'),
    $caption: mat-typography-level($font-size - 1, line-height($font-size - 1), lighter, '"Arial-light", sans-serif'),
    $headline: mat-typography-level($font-size * 3, line-height($font-size * 3), normal),
    $subheading-1: mat-typography-level($font-size + 4, line-height($font-size + 4), normal),
    $subheading-2: mat-typography-level($font-size, line-height($font-size), normal),
    $input: mat-typography-level($font-size, line-height($font-size), normal),
    $button: mat-typography-level($font-size, line-height($font-size), normal),
);

@include create_material_theme("default", $typography, $md-primary, $md-accent, $md-warn) {
  --black: #212322;
  --red: #A73333;
  --purple: #7033A6;
  --purple-light: #7D42AD;
  --brown-grey: #75666E;
  --pink: #F6D5E6;
  --pink-37: #FCEFF6;
  --gray-0: #707070;
  --gray-5: #F5F5F5;
  --green: #339ba6;
  --fushia: #a63368;

  --background: #FFF;
  --font-size: $font-size;

  //picto color #3387A6
  --turquoise: #3387A6;

  height: 100%;
  overflow: hidden;
  //background-color: var(--background);
}
