@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}

.material-icons {
  font-size: inherit;
}

mat-icon {
  &::before, &:before {
    display: flex;
  }

  &.mdi-32px {
    width: 32px;
    height: 32px;

    &::before {
      font-size: 32px;
    }
  }

  &.mdi-42px {
    width: 42px;
    height: 42px;

    &::before {
      font-size: 42px;
    }
  }

  &.mdi-400px {
    height: 400px;
    width: 400px;

    &::before {
      font-size: 400px;
    }
  }

  &.mdi-18px {
    width: 18px;
    height: 18px;
  }
}
