@import "../../../layout_base";
@import "../../../layout";

button {
  @include layout-align-center-center;

  color: var(--foreground-base);
  cursor: pointer;
  border-radius: 4px;
  font-family: "Gotham-Book", sans-serif !important;

  span.mat-button-wrapper {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: unset;
    }

    .mat-icon
    {
      margin-right: 5px;
      height: auto !important;
      width: auto !important;
    }

    .btn-text {
      display: flex;
      align-items: center;
      align-content: center;
      margin-top: 2px;

      .count {
        span:first-of-type {
          border-left: 1px solid var(--background-disabled-list-option);
          margin-left: 10px;
          padding-left: 8px;
        }
      }

    }
  }

  &[disabled="true"] span.mat-button-wrapper .btn-text .count span:first-of-type {
    border-left: 1px solid rgba(26, 33, 39, 0.26);
  }
}

.mat-flat-button[color="primary"] {
  padding: 0 24px 0 18px;
  //color: white !important;
  //background-color: var(--primary-A200) !important;
}

.mat-raised-button {
  padding: 0 15px 0 12px;
  color: white !important;
}

.black-btn {
  background-color: #3F3540 !important;
  color: white !important;

  &[disabled="true"] {
    background-color: var(--background-disabled-button) !important;
  }
}

.stroked-btn-style {
  color: var(--foreground-base) !important;
  border-radius: 2px;
  border: 1px solid rgba(26, 33, 39, 0.12);

  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 100%;
  line-height: 37px !important;
  box-sizing: border-box;
  position: relative;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  padding: 0 13px 0 16px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: var(--background-hover);
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;

    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 0 !important;
        margin-top: 0 !important;
        border: unset !important;

        mat-select {
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                color: var(--foreground-base) !important;
                text-transform: uppercase;
                font-family: "Arial-Light", sans-serif;
                font-weight: lighter;
              }
            }

            .mat-select-arrow-wrapper {
              vertical-align: unset !important;

              .mat-select-arrow {
                color: var(--foreground-base) !important;
                border-left: 10px solid transparent !important;
                border-right: 10px solid transparent !important;
                border-top: 10px solid !important;
              }
            }
          }
        }

        .mat-form-field-label-wrapper {
          top: 0 !important;
          padding-top: 0 !important;

          .mat-form-field-label {
            top: 0 !important;

            & > span:first-of-type {
              color: var(--foreground-base) !important;

              &::before {
                font-family: "Gotham-Bold", sans-serif;
                font-weight: bold;
                text-transform: uppercase;
                content: "select";
              }

              &::after {
                font-family: "Gotham-Light", sans-serif;
                font-weight: lighter;
                text-transform: uppercase;
                content: "template";
              }

            }
          }
        }
      }
    }

    .mat-form-field-underline {
      display: none !important;
    }

    .mat-form-field-subscript-wrapper {
      display: none !important;
    }
  }


}

