.page {
  background-size: cover !important;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 25px 40px 0 40px;

    & > div {
      &:first-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        & > div {
          cursor: pointer;

          &:first-of-type {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
              color: var(--purple);
              font-family: "Gotham-Bold", sans-serif;
              font-weight: bold;
              font-size: 30px;
              line-height: 28px;
            }
          }

          &:last-of-type {
            display: flex;
            flex-direction: column;
            justify-content: center;

            span {
              font-size: 22px;
              white-space: nowrap;

              &:last-of-type {
                line-height: 22px;
              }
            }
          }

          &.separator {
            background-color: var(--black);
            height: 51px;
            width: 3px;
            margin: 0 15px;
          }
        }
      }
    }

    .top-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 15px;

      span {
        font-family: "Gotham-Medium", sans-serif;
        font-weight: 500;
        cursor: pointer;
        white-space: nowrap;

        &:not(:last-of-type) {
          margin-right: 47px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .page-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    min-width: 1280px;

    .middle-content {
      width: 1024px;
      position:relative;
      margin: 0 auto;
      padding: 20px 0px 80px 0;

      .content-header {
        margin-bottom: 35px;

        div:first-of-type {
          display: flex;
          flex-direction: column;

          & > span {
            font-family: "Gotham-Black", sans-serif;
            font-weight: 1000;
            font-size: 40px;

            &:last-of-type {
              line-height: 30px;
              color: var(--purple);
            }
          }
        }

        & > span {
          display: block;
          margin-top: 30px;
          font-family: "Gotham-Medium", sans-serif;
          font-weight: 500;
        }
      }
    }
  }

  .footer {
    padding: 4px 40px 3px 40px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > div {
      span:first-of-type {
        font-family: "Gotham-Bold", sans-serif;
        font-weight: bold;
        color: var(--purple);
      }
    }
  }
}

.content {
  margin-top: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .outer-circle {
    border: none;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 305px;
    height: 305px;
    position: relative;
    cursor: pointer;

    &:hover {
      .circle-inner {
        .circle-inside {
          box-shadow: 0 0px 0px rgba(0,0,0,0);
        }
      }
    }

    &:not(:last-of-type) {
      margin-right: 50px;
    }

    &.circle-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    mat-icon {
      color: var(--red);
      position: absolute;
      left: 8px;
      top: 33px;
      background: white;
      border-radius: 100%;
      width: 42px;
      height: 40px;
      line-height: 40px;
      text-align: center;
    }

    .circle-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: var(--pink);
      border-radius: 100%;
      width: 271px;
      height: 271px;

      .circle-inside {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px rgba(33,35,34,.18);
        transition: box-shadow 0.3s ease-in-out;
        background-color: white;
        border-radius: 100%;
        width: 235px;
        height: 235px;

        div {
          svg {
            width: 150px;
            height: 110px;
          }
        }

        span {
          font-family: "Gotham-Medium", sans-serif;
          font-weight: 500;
          margin-top: 10px;
        }
      }
    }
  }
}

.form-content {
  background-color: white;
  border-radius: 15px;
  position: relative;
  padding: 55px 44px 20px 44px;
  display: flex;
  flex-direction: column;

  form {
    & > div {
      &:first-of-type {
        .actions {
          display: flex;
          flex-direction: row;
          align-items: center;

          & > div:first-of-type {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;

            button {
              margin-right: 15px;
              background-color: var(--brown-grey);
            }
          }
        }
      }
    }

    mat-card {
      margin-bottom: 25px;
    }

    .actions-button-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & > button {
        &:not(:last-of-type) {
          margin-right: 15px;
        }

        &[mat-flat-button] {
          color: white;

          &.green {
            background-color: var(--green);
          }

          &.red {
            background-color: var(--fushia);
          }

          &[disabled="true"] {
            background-color: rgba(0, 0, 0, 0.12) !important;
          }

        }
      }
    }
  }
}
