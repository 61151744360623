mat-toolbar {
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: inherit;
  background-color: white !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 1000px;
  width: 100%;

  &#sub-toolbar {
    width: 100%;
    min-height:70px;
    background-color: var(--primary-500) !important;
    justify-content: center;

    &.empty {
      min-height: 15px !important;
      height: 15px;
    }

    .mat-toolbar-row {
      padding: 0 20px;

      .separator {
        background-color: var(--mauve-fonce);
      }

      .filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-width: 500px;
        color: white;

        & > app-select-multi {
          width: 100%;

          &:not(:last-of-type) {
            margin-right: 15px;
          }
        }

        mat-form-field {

          &:not(:last-of-type) {
            margin-right: 15px;
          }

          .mat-form-field-wrapper {
            padding-bottom: 0 !important;

            .mat-form-field-outline, .mat-form-field-outline-thick {
              color: #A5A4BF !important;
            }
          }
        }

      }

      .left-rate {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: flex-start;
        padding-right: 25px;

        & > div {
          display: flex;
          align-items: center;

          &:first-of-type {
            margin-bottom: 8px;
          }

          & > div:first-of-type {
            display: flex;
            align-items: center;
          }
        }

        span {
          color: white;
          font-size: $font-size;
          font-family: "Gotham-Book", sans-serif;
        }
      }

      .right-rate {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: flex-end;

        & > div {
          display: flex;
          align-items: center;

          &:first-of-type {
            margin-bottom: 8px;
          }

          & > div:first-of-type {
            display: flex;
            align-items: center;
          }
        }

        span {
          color: white;
          font-size: $font-size;
          font-family: "Gotham-Book", sans-serif;
        }
      }

      .nav-calendar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        mat-icon {
          cursor: pointer;
          color: #A5A4BF;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            color: white;
          }
        }

        & > span:first-of-type {
          color: white;
          font-family: "Gotham-Book", sans-serif;
          font-size: 20px;
          text-transform: uppercase;
          padding: 0 15px;
          width:175px;
          text-align: center;
        }
      }
    }
  }

  a {
    color: var(--foreground-base);
  }

  mat-toolbar-row {
    height : auto !important;
  }

  .mat-toolbar-multiple-rows {
    min-height: 50px;
  }

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    padding: 0;
  }

  .mat-toolbar-row {
    align-items: center;
    align-content: center;
    padding: 0px 10px;

    & > mat-icon:first-of-type {
      height: 42px;
      width: 48px;
      position: relative;
      margin-left: 10px;
      cursor: pointer;
    }

    .separator {
      background-color: var(--background-disabled-list-option);
      height: 50px;
      width: 1px;
      margin: 0 25px;
    }

    #toolbar-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: center;
      cursor: pointer;
      padding-bottom: 5px;

      span{
        color: var(--primary-50-contrast);
        text-align: left;

        &:first-of-type {
          text-transform: uppercase;
          font-family: "Gotham-Bold", sans-serif;
          font-size: 30px;
          font-weight: bold;
        }

        &:last-of-type {
          font-family: "Arial-Light", sans-serif;
          text-transform: none;
          font-weight: lighter;
          font-size: $font-size;
        }
      }
    }

    #h-menu {
      list-style: none;
      display: flex;

      li {
        display: flex;
        align-items: center;
        overflow: hidden;

        &:not(:last-of-type) {
          margin-right: 15px;
        }

        mat-icon {
          display: flex;
          align-items: center;
          color: #A5A4BF;
          margin-right: 5px;
        }

        span {
          text-transform: capitalize;
          font-size: $font-size;
          font-family: "Gotham-Book", sans-serif;
          max-width: 0px;
          transition: max-width .50s ease-in-out;
        }

        &:hover {
          cursor: pointer;
          max-width: fit-content;

          mat-icon {
            color: inherit;
          }

          span {
            max-width: 300px;
            transition: max-width .50s ease-in-out;
          }
        }
      }
    }
  }
}

.spacer {
  flex: 1 1 auto;
}

.profile {
  margin-right: 10px;

  .profile-wrapper {
    @include layout-row;
    @include layout-align-center-center;

    .profile-details {
      @include layout-column;

      align-items: flex-end;
      align-content: flex-end;
      width: 100%;

      span {
        font-size: $font-size;
        text-transform: uppercase;

        sup {
          font-size: 9px;
        }
      }

      & > div {
        margin: 0 !important;

        &:first-of-type {
          line-height: 18px;

          & > span {
            font-family: "Gotham-Bold", sans-serif;
            font-weight: bold;
            font-size: $font-size;

            &:first-of-type {
              margin-right: 5px;
            }
          }
        }

        &:last-of-type {
          line-height: 13px;

          & > span {
            &:not(:last-of-type) {
              margin-right: 5px;
            }

            font-family: "Arial-Light", sans-serif;
            font-weight: lighter;
            line-height: 13px;
          }
        }
      }

      & > span {
        font-family: "Arial-Light", sans-serif;
        font-weight: lighter;
        line-height: 24px;
        margin: 0 !important;
      }
    }

    .profile-picture {
      display: flex;
      align-items: center;
      align-content: center;

      img {
        height: 50px;
        border-radius: 50%;
        vertical-align: middle;
      }

      .list-user-picture-anonymous {
        width: 32px;
        height: 32px;
        text-align: center;
        margin: 10px 0;

        display: flex;
        align-items: center;
        align-content: center;

        span {
          width: 100%;
          font-size: 32px;
          text-align: center;
        }
      }
    }
  }
}

