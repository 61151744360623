svg.tea {
  --secondary: #33406f;
}
svg.tea #teabag {
  transform-origin: top center;
  transform: rotate(3deg);
  animation: swing 2s infinite;
}
svg.tea #steamL {
  stroke-dasharray: 13;
  stroke-dashoffset: 13;
  animation: steamLarge 2s infinite;
}
svg.tea #steamR {
  stroke-dasharray: 9;
  stroke-dashoffset: 9;
  animation: steamSmall 2s infinite;
}
@-moz-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}
@-webkit-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}
@-o-keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}
@keyframes swing {
  50% {
    transform: rotate(-3deg);
  }
}
@-moz-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}
@-webkit-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}
@-o-keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}
@keyframes steamLarge {
  0% {
    stroke-dashoffset: 13;
    opacity: 0.6;
  }
  100% {
    stroke-dashoffset: 39;
    opacity: 0;
  }
}
@-moz-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}
@-webkit-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}
@-o-keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}
@keyframes steamSmall {
  10% {
    stroke-dashoffset: 9;
    opacity: 0.6;
  }
  80% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 27;
    opacity: 0;
  }
}
