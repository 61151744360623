.mat-select-panel {
  border-radius: 0 !important;
}

.mat-selection-list {
  & > div {
    &:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 0 0 15px 0;
    }
  }

  p {
    text-align: right;
  }
}
