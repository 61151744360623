mat-checkbox {
  //color: var(--gray);

  &:not(:last-of-type) {
    margin-right: 15px;
  }

  .mat-checkbox-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .mat-checkbox-inner-container {
      margin: 0 8px auto auto;

      .mat-checkbox-frame {
        //border-color: var(--accent-500);
      }
    }

    .mat-checkbox-label {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
