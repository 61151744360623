mat-expansion-panel {
  border-radius: 0 !important;
  margin-bottom: 25px !important;
  margin-top: 25px !important;

  .mat-expansion-panel-body {
    padding: 16px 24px !important;
  }

  mat-expansion-panel-header {
    mat-panel-title {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 25px;

        svg {
          width: 65px;
          height: auto;
        }
      }
    }
  }

  .bg {
    position: absolute;
    right: 35px;
    bottom: 80px;

    svg {
      width: 240px;
      height: auto;
    }
  }

  .form-inner {
    display: flex;
    flex-direction: row;

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;

      &:last-of-type {
        margin-left: 20px;
      }
    }
  }

  .expansion-no-data {
    width: 100%;
    position: relative;
    text-align: center;
    display: block;
    padding: 15px 0;
  }

  .flex-row {
    display: flex;
    flex-direction: row;

    mat-form-field {
      margin: 0 10px;
    }
  }

  .alert {
    color: var(--red);
  }

  .btn-add {
    width: 100%;
    margin-top: 15px;
  }
}
