@font-face {
  font-family: 'Gotham-Medium';
  src: url('#{$custom-font-path-gotham}/medium/Gotham-Medium.eot');
  src: url('#{$custom-font-path-gotham}/medium/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
  url('#{$custom-font-path-gotham}/medium/Gotham-Medium.woff2') format('woff2'),
  url('#{$custom-font-path-gotham}/medium/Gotham-Medium.woff') format('woff'),
  url('#{$custom-font-path-gotham}/medium/Gotham-Medium.ttf') format('truetype'),
  url('#{$custom-font-path-gotham}/medium/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('#{$custom-font-path-gotham}/bold/Gotham-Bold.eot');
  src: url('#{$custom-font-path-gotham}/bold/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$custom-font-path-gotham}/bold/Gotham-Bold.woff2') format('woff2'),
  url('#{$custom-font-path-gotham}/bold/Gotham-Bold.woff') format('woff'),
  url('#{$custom-font-path-gotham}/bold/Gotham-Bold.ttf') format('truetype'),
  url('#{$custom-font-path-gotham}/bold/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Black';
  src: url('#{$custom-font-path-gotham}/black/Gotham-Black.eot');
  src: url('#{$custom-font-path-gotham}/black/Gotham-Black.eot?#iefix') format('embedded-opentype'),
  url('#{$custom-font-path-gotham}/black/Gotham-Black.woff2') format('woff2'),
  url('#{$custom-font-path-gotham}/black/Gotham-Black.woff') format('woff'),
  url('#{$custom-font-path-gotham}/black/Gotham-Black.ttf') format('truetype'),
  url('#{$custom-font-path-gotham}/black/Gotham-Black.svg#Gotham-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('#{$custom-font-path-gotham}/book/Gotham-Book.eot');
  src: url('#{$custom-font-path-gotham}/book/Gotham-Book.eot?#iefix') format('embedded-opentype'),
  url('#{$custom-font-path-gotham}/book/Gotham-Book.woff2') format('woff2'),
  url('#{$custom-font-path-gotham}/book/Gotham-Book.woff') format('woff'),
  url('#{$custom-font-path-gotham}/book/Gotham-Book.ttf') format('truetype'),
  url('#{$custom-font-path-gotham}/book/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

