.form-error {
  margin-bottom: 20px;
  text-align: center;
  background-color: var(--primary-50);
  border-radius: 4px;
  padding: 15px;
}

.form-no-error {
  margin-bottom: 20px;
  text-align: center;
  background-color: rgba(0, 123, 47, 0.15);
  border-radius: 4px;
  padding: 15px;
}

form {
  strong {
    font-family: "Gotham-Bold", sans-serif;
    font-weight: bold;
  }

  .field-box:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .field-box {
    display: flex;
    flex-direction: column;
  }

  .field-box-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;

    .field-box-label {
      margin-right: 10px;
    }
  }

  .field-box-label {
    margin-bottom: 5px;
  }

  .btn-choose {
    width: 100%;
  }

  .field-wrap {
    margin-left: 15px;

    flex-flow: row wrap;

    .box-column {
      width: 150px;
      background-color: var(--background-app-bar);
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      padding: 15px;

      .box-column-label {
        margin-top: 5px;
      }
    }
  }

  .field-multi-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .field-multi-box-inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;

    .field-box{
      margin-bottom: 0px;
    }

    .field-box:first-of-type {
      flex: 1;
    }
  }
}

mat-error {
  margin-top: -11px;
  margin-bottom: 11px;

  span {
    color: white !important;
    background-color: #ff6565;
    border-radius: 4px;
    padding: 5px;
  }
}
