@import '~@angular/material/theming';
@import 'src/assets/styles/themes/functions';

@mixin generate_variable($map, $array_index)
{
    @each $data in $array_index {
      @include create_variable($map, $data);
    }
}

@mixin create_variable($map, $data)
{
  @each $name, $color in map-get($map, $data) {
    @if type-of($color) == 'map' {
      @include create_variable($color, $data);
    } @else {
      @if $color != null {
        --#{$data}-#{$name}: #{$color};
      }
    }
  }
}

@mixin create_material_theme($name, $typography, $primary, $accent, $warn) {

    @include mat-core($typography);

    $mat-primary: mat-palette($primary);
    $mat-accent: mat-palette($accent);
    $mat-warn: mat-palette($warn);

    .#{$name}-theme {
        $_theme: mat-light-theme($mat-primary, $mat-accent, $mat-warn);

        $f: map_get(map_get($mat-primary, contrast), 500);
        $foreground: custom-foreground($f);
        $theme: map-merge($_theme, (foreground: $foreground));

        @include angular-material-theme($theme);
        @include angular-material-typography($typography);
        @include generate_variable($theme, ('background', 'foreground', 'primary', 'warn', 'accent'));

        @content;
    }
}
