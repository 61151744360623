@import "../../../layout_base";
@import "../../../layout";

#interact-table {
  thead {

    & > .mat-header-row {
      height: 42px;

      &:first-of-type > .mat-header-cell {
        height: 30px !important;
        border-top: unset !important;
        color: var(--mauve) !important;
        text-align: center !important;
        font-family: "Gotham-Bold", sans-serif !important;
        font-weight: bold !important;
        font-size: 18px !important;

        &:not(:first-of-type) {
          background-color: white !important;
        }

        &:first-of-type {
          background-color: var(--mauve-clair);
        }

        &:not(:last-of-type),
        &:not(:first-of-type){
          border-right: unset !important;
          border-left: 1px solid var(--mauve-clair);
        }
      }

      &:nth-of-type(2) > .mat-header-cell {
        background-color: var(--mauve);
        color: white !important;
        text-align: center !important;
        border: none !important;
        font-family: "Gotham-Book", sans-serif !important;
        font-size: $font-size !important;
        font-weight: unset;

        &:not(:last-of-type),
        &:not(:first-of-type){
          border: unset !important;
        }
      }

      &:nth-of-type(3) > .mat-header-cell {
        padding: 0 5px !important;
        color: var(--foreground-base) !important;
        text-align: center !important;
        font-family: "Gotham-Book", sans-serif !important;
        font-size: $font-size !important;
        text-transform: uppercase;
        font-weight: unset;

        &:nth-of-type(even) {
          background-color: var(--mauve-clair);
        }

        &:nth-of-type(odd) {
          background-color: white;
        }

        &:not(:last-of-type),
        &:not(:first-of-type){
          border: unset !important;
        }
      }

      & > .mat-header-cell {
        padding: 0;
        text-align: center;
        min-width: 24px;
      }

      & > .currentDay {
        background-color: var(--mauve-fonce) !important;
      }

    }
  }

  th.mat-header-cell,
  td.mat-cell {
    width: 0px;
    position: relative;
    padding: 0;
  }

  tbody {
    tr {
      background-color: white !important;

      &:nth-of-type(even) {
        background-color: white !important;
      }

      td {
        position: relative;
        display: table-cell;

        &:not(:last-of-type),
        &:not(:first-of-type){
          border: unset !important;
          border-top: 1px solid var(--mauve) !important;
        }

        &:not(:first-of-type):nth-of-type(even) {
          background-color: var(--mauve-clair) !important;
        }

        &.mat-column-none {
          & > div:first-of-type {
            padding: 5px 15px;
            min-width: 400px;
            display: flex;
            flex-direction: row;
            align-items: center;

            span:first-of-type {
              text-transform: capitalize;
              flex: 1;
            }

            .separator {
              background-color: var(--mauve-clair);
              height: 15px;
              width: 1px;
              margin: 0 15px;
            }

            .cell-rate {
              display: flex;
              flex-direction: column;
              align-content: center;
              align-items: flex-end;
              min-width: 177px;

              & > div {
                display: flex;
                align-items: center;

                & > div:first-of-type {
                  display: flex;
                  align-items: center;
                }
              }

              span {
                font-size: 10px;
                font-family: "Gotham-Book", sans-serif;
              }
            }

          }
        }

        &.mat-cell {
          .disable-cell {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            mat-icon {
              color: var(--background-hover);
            }
          }
        }
      }
    }
  }
}

table {

  &.loading {
    tr.table-loading {
      display: table-row;
    }
    tr.table-noresult {
      display: none;
    }
  }

  &:not(.loading) {
    tr.table-loading {
      display: none;
    }
    tr.table-noresult {
      display: none;
    }
    tr.table-noresult.display {
      display: table-row;
    }
  }

  &.mat-table {
    background: none !important;
    width: 100%;

    thead {

      tr {
        //background-color: var(--primary-500) !important;

        &.table-loading {
          height: 4px;
        }

        &.table-noresult {
          background-color: #fff !important;

          th.table-noresult {
            text-align: center;
          }
        }

      }

      tr th {
        padding-right: 24px;
        padding-left: 24px;
        min-width: max-content;

        &.table-loading {
          border: none;
          padding: 0;
        }

        &.mat-column-action,
        &.mat-column-actions {
          text-align: right;
        }

        &:not(.mat-column-photo),
        &:not(.mat-column-action),
        &:not(.mat-column-actions) {
          background-color: var(--background-background);
          color: var(--foreground-base) !important;
        }

        &.custom-header {
          display: flex;
          flex-direction: column;
          height: inherit;
          align-items: flex-end;
          justify-content: center;
        }

        &.mat-header-cell {
          //color:  #fff !important;
          font-family: "Gotham-Bold", sans-serif;
          font-weight: bold;
          text-transform: uppercase;
        }

        .mat-sort-header-container {
          button {
            flex: 1;
            color:  #fff !important;
            font-family: "Gotham-Book", sans-serif;
            font-weight: initial;
            text-transform: uppercase;

            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            text-align: left;

            & > span {
              line-height: 15px;
              font-size: $font-size;
              width: 100%;
            }
          }

          .mat-sort-header-arrow {
            //color: #fff !important;
          }
        }
      }
    }

    tbody {
      tr {
        background-color: white !important;

        &:nth-of-type(even) {
          background-color: var(--background-background) !important;
        }

        td {
          padding-right: 24px;
          padding-left: 24px;
          min-width: max-content;

          &.mat-column-status {
            & > div:first-of-type {
              display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;

              & > span:first-of-type {
                margin-left: 5px;
              }
            }
          }

          &.mat-column-photo {
            text-align: center;
          }

          &.mat-column-action,
          &.mat-column-actions,
          &.mat-column-alert{
            text-align: right;
          }

          & > div:last-child {
            display: inline-flex;
            align-items: center;
            align-content: center;

            a {
              color: inherit;
              margin-top: 5px;
            }

            button {
              padding: 0 !important;

              &:not(&:last-of-type) {
                min-width: 32px !important;
              }

              &:last-of-type {
                min-width: 0px !important;
              }

              .mat-icon {
                margin-right: 0 !important;
              }
            }
          }
        }
      }

      tr {
        &:first-of-type {
          td.mat-cell {
            &:first-of-type,
            &:last-of-type {
              border-top: 1px solid var(--background);
            }
          }
        }

        &:last-of-type {
          td.mat-cell {
            border-bottom: 1px solid var(--background) !important;
          }
        }

        td.mat-cell:first-of-type {
          border-left: 1px solid var(--background);
        }

        td.mat-cell:last-of-type {
          border-right: 1px solid var(--background);
        }

        border-right: 1px solid var(--background);
      }
    }

    tfoot {
      background: none !important;

      tr.action td {
        border:0;
      }
    }

    th.mat-header-cell,
    td.mat-cell {
      border-bottom: none !important;

      &:not(:last-of-type) {
        border-right: 1px solid var(--background);
      }
    }

    th.mat-header-cell {
      &:not(:last-of-type) {
        border-right: 1px solid var(--background);
      }

      &:not(:first-of-type):not(:last-of-type) {
        border-top: 1px solid var(--background);
      }
    }
  }
}

.list-user-picture {
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;

  mat-icon {
    position: absolute;
    top: 0;
    left: -24px;
  }

  img {
    border-radius: 50%;
    padding: 10px 0;
  }

  .list-user-picture-leavers {
    text-align: center;
    margin: 10px 0;

    display: flex;
    align-items: center;
    align-content: center;

    span {
      color: #A5A4BF;
      width: 100%;
    }
  }

  .list-user-picture-anonymous {
    background-color: #A5A4BF;
    width: 40px;
    text-align: center;
    height: 40px;
    margin: 10px 0;
    border-radius: 50%;

    display: flex;
    align-items: center;
    align-content: center;

    span {
      color: #E5E5EB;
      width: 100%;
      font-size: 32px;
      font-family: "Gotham-Bold", sans-serif;
      font-weight: bold;
    }
  }
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container,
[mat-sort-header].cdk-program-focused .mat-sort-header-container {
  border: none !important;
  border-bottom: none !important;
}
