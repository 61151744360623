.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
    left: -25px !important;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
    top: -19px !important;
}

.mat-badge-warn .mat-badge-content {
    background: white !important;
    border: 1px solid var(--warn-500);
    color: var(--foreground-base) !important;
}

.mat-badge-content {
    background: white !important;
    border: 1px solid var(--primary-500);
    color: var(--foreground-base) !important;
}