@mixin layout() {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin layout-column() {
  @include layout;

  flex-direction: column;

  &.layout-nomargin > * {
    margin: 0px !important;
  }

  &:not(.layout-nomargin) > * {
    margin: $layout-gutter-width / 2 0 $layout-gutter-width / 2 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin layout-row() {
  @include layout;

  flex-direction: row;

  &.layout-nomargin > * {
    margin: 0px !important;
  }

  &:not(.layout-nomargin) > * {
    margin: 0 $layout-gutter-width;

    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}

@mixin layout-align-horizontal-start {
  justify-content: flex-start;
}

@mixin layout-align-horizontal-center {
  justify-content: center;
}

@mixin layout-align-horizontal-end {
  justify-content: flex-end;
}

@mixin layout-align-horizontal-space-around {
  justify-content: space-around;
}

@mixin layout-align-horizontal-space-between {
  justify-content: space-between;
}

@mixin layout-align-vertical-start {
  align-items: flex-start;
  align-content: flex-start;
}

@mixin layout-align-vertical-center {
  align-items: center;
  align-content: center;
  max-width: 100%;

  & > * {
    max-width: 100%;
    box-sizing: border-box;
  }
}

@mixin layout-align-vertical-end {
  align-items: flex-end;
  align-content: flex-end;
}

@mixin layout-align-vertical-stretch {
  align-items: stretch;
  align-content: stretch;
}

@mixin layout-align() {
  @include layout-align-horizontal-start;
  @include layout-align-vertical-stretch;
}

@mixin layout-align-start-center {
  @include layout-align-horizontal-start;
  @include layout-align-vertical-center;
}

@mixin layout-align-center-center {
  @include layout-align-horizontal-center;
  @include layout-align-vertical-center;
}

@mixin layout-align-end-center {
  @include layout-align-horizontal-end;
  @include layout-align-vertical-center;
}

@mixin layout-align-space-between-center {
  @include layout-align-horizontal-space-between;
  @include layout-align-vertical-center;
}

@mixin layout-flex {
  flex: 1;
  box-sizing: border-box;
}

@mixin layout-flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box;
}

@mixin layout-flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box;
}

@mixin layout-flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box;
}

@mixin layout-flex-none {
  flex: 0 0 auto;
  box-sizing: border-box;
}

@mixin layout-flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box;
}

@mixin layout-flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box;
}

@mixin layout-flex-5($direction) {
  flex: 1 1 5%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 5%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 5%;
  }
}

@mixin layout-flex-10($direction) {
  flex: 1 1 10%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 10%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 10%;
  }
}

@mixin layout-flex-15($direction) {
  flex: 1 1 15%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 15%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 15%;
  }
}

@mixin layout-flex-20($direction) {
  flex: 1 1 20%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 20%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 20%;
  }
}

@mixin layout-flex-25($direction) {
  flex: 1 1 25%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 25%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 25%;
  }
}

@mixin layout-flex-33($direction) {
  flex: 1 1 33.33%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 33.33%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 33.33%;
  }
}

@mixin layout-flex-40($direction) {
  flex: 1 1 40%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 40%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 40%;
  }
}

@mixin layout-flex-50($direction) {
  flex: 1 1 50%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 50%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 50%;
  }
}

@mixin layout-flex-66($direction) {
  flex: 1 1 66.66%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 66.66%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 66.66%;
  }
}

@mixin layout-flex-80($direction) {
  flex: 1 1 80%;
  box-sizing: border-box;
  @if $direction == row {
    max-width: 80%;
    max-height: 100%;
  } @else if $direction == column {
    max-width: 100%;
    max-height: 80%;
  }
}

@mixin media-content-xs {
  @media (max-width: $layout-breakpoint-xs - 1) {
    @content
  }
}

@mixin media-content-gt_xs {
  @media (min-width: $layout-breakpoint-xs) {
    // BIGGER THAN Xtra-SMALL SCREEN
    @content;
  }
}

@mixin media-content-sm {
  @media (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm - 1) {
    @content;

  }
}

@mixin media-content-gt_sm {
  @media (min-width: $layout-breakpoint-sm) {
    // BIGGER THAN SMALL SCREEN
    @content;
  }
}

@mixin media-content-md {
  @media (min-width: $layout-breakpoint-sm) and (max-width: $layout-breakpoint-md - 1) {
    @content
  }
}

@mixin media-content-gt_md {
  @media (min-width: $layout-breakpoint-md) {
    // BIGGER THAN MEDIUM SCREEN
    @content
  }
}

@mixin media-content-lg {
  @media (min-width: $layout-breakpoint-md) and (max-width: $layout-breakpoint-lg - 1) {
    @content;
  }
}

@mixin media-content-gt_lg {
  @media (min-width: $layout-breakpoint-lg) {
    @content
  }
}