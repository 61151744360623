.mat-tab-group {
  min-height: 432px;

  .mat-tab-header {
    border: none !important;

    .mat-tab-label-container {
      .mat-tab-list {

        .mat-ink-bar {
          display: none !important;
        }

        .mat-tab-labels {
          align-items: center;
          height: 28px;

          .mat-tab-label {

            &:first-of-type {
              padding-left: 0;
            }

            &:not(:last-of-type) {
              border-right: 1px solid var(--black);
            }

            &:hover,
            &.mat-tab-label-active {
              font-family: "Gotham-Bold", sans-serif;
              font-weight: bold;
              font-size: 16px;
              color: var(--black);
              opacity: 1;
            }

            &:not(.mat-tab-label-active) {
              font-family: "Gotham-Book", sans-serif;
              font-weight: 500;
              font-size: 16px;
              color: rgba(33,35,34,.25);
            }

          }
        }
      }
    }
  }

  .mat-tab-body-wrapper {
    margin-top: 37px;

    .mat-tab-body {
      .mat-tab-body-content {
        display: flex;
        flex-direction: column;

        & > div {
          display: flex;
          flex-direction: column;

          & > span {
            &:first-of-type {
              font-family: "Gotham-Medium", sans-serif;
              font-weight: 500;
              margin-bottom: 20px;
            }
          }

          &:not(:last-of-type) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
