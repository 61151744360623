.cdk-overlay-container {
  background-color: transparent !important;

  .cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.70) !important;
  }

  .mat-autocomplete-panel {
    .mat-option {
      .mat-option-text {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;

        .mat-checkbox {
          .mat-checkbox-inner-container:hover {
            .mat-checkbox-persistent-ripple {
              opacity: 0 !important;
            }
          }
        }

        .mat-option-text-label {
          margin-top: 5px;
          margin-left: 15px;
        }
      }
    }
  }
}

.cdk-overlay-pane {
  .mat-menu-panel {
    max-width: unset;

    .mat-menu-content {
      menu {
        margin: 0;
      }
    }
  }
}
