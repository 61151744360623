mat-radio-button{

  &.mat-radio-checked {
    .mat-radio-label {
      .mat-radio-container {
        .mat-radio-outer-circle {
          border-color: rgba(26, 33, 39, 0.54) !important;
        }
      }
    }
  }

  .mat-radio-label {
    .mat-radio-container {
      .mat-radio-outer-circle {
        border-width: 1px;
      }
    }
  }

  mat-form-field.mat-form-field{
    width: 220px;
    margin-right: 20px;
  }
}

mat-radio-group {

  &.multi-dimensions {
    mat-radio-button {
      .mat-radio-label {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .mat-radio-label-content {
          display: flex;
          flex-direction: column;

          & > div {
            &:first-of-type {
              display: flex;
              flex-direction: column;
              margin-right: 40px;

              & > span {
                &:first-of-type {
                  margin-top: 3px;
                }
              }

              & > div {
                display: flex;
                flex-direction: column;
                margin-top: 15px;

                mat-checkbox {
                  margin-right: 0px !important;

                  &:first-of-type {
                    margin-bottom: 5px;
                  }

                  .mat-checkbox-layout {
                    justify-content: flex-start !important;

                    .mat-checkbox-inner-container {
                      margin: 0 8px 0 0 !important;
                    }

                    .mat-checkbox-label {
                      margin-top: 2px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
